.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  align-self: center;
  max-height: 500px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.post-slider .swiper-slide {
  max-height: 700px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  width: 80px;
  height: 80px;
  justify-content: center;
  padding: 0;
  align-items: center;
}
.swiper-button-next {
  width: 80px;
  height: 80px;
  justify-content: center;
  padding: 0;
  align-items: center;
}

.swiper-button-prev::after {
  content: url("../../../public/img/slider/noun-chevron-1180170-left.svg");
}
.swiper-button-next::after {
  content: url("../../../public/img/slider/noun-chevron-1180170-right.svg");
}

.swiper-pagination-bullet {
  background: #AB7928;
}
